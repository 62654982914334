<template>
  <div>
    <ShopOrderSendLinkModal
      ref="shop_order_send_link_modal"
      :shop_order_ids="selected_shop_order_ids"
      :created_from="filters.created_from"
      :created_to="filters.created_to"
      :statuses="filters.statuses"
    />

    
    <ShopOrderFormModal
      ref="shop-order-modal"
      :item="selected_item"
      @updated="shop_order_updated"
      @deleted="shop_order_deleted"
    />


    <MemberEditorCompactModal
      ref="member-editor-modal"
      class="mt-8"
      :member_id="member_id"
      @updated="member_updated"

    />

    <div class="d-flex justify-content-end mt-2">
      <a
        v-if="isTHS"
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click.prevent="send_links_modal_open"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('SHOP_ORDER.SEND_LINK')}}</a
      >

      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click.prevent="export_xlsx_clicked"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('COMMON.EXPORT_XLSX')}}</a
        >

    </div>

    <div class="ml-4 mr-4">

      <b-row>
        <b-col lg="3" sm="3">
          <b-form-group
            id="input-group-created_from"
            :label="$t('COMMON.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
        </b-col>

        <b-col lg="3" sm="3">
          <b-form-group
            id="input-group-created_to"
            :label="$t('COMMON.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" sm="12">
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            :placeholder="$t('SHOP_ORDER.SEARCH_PARAMS')"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" >
          <div class="bottom-alignment">
            <strong>{{ $t('SHOP_ORDER.NUM_ROWS') }}:</strong> {{ totalRows }}
          </div>
        </b-col>

        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 250]"
              v-model="per_page"
            />
            <b-pagination
              class="mb-0 mt-2 align-bottom"
              v-model="current_page"
              :total-rows="totalRows"
              :per-page="per_page"
              aria-controls="orders-table"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <b-table
        id="orders-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="headers"
        :filter="filters"
        :filter-function="customFilter"
        :items="ajax_pagination"
        :per-page="per_page"
        :current-page="current_page"
        :tbody-tr-class="rowClass"
        head-variant="light"
        ref="orderTable"
        selectable
        selected-variant=""
        no-select-on-click
        @filtered="on_filtered"
        @sort-changed="on_sort_changed"
      >
        <template #cell(selected)="row">
          <v-checkbox
            v-model="row.item.selected"
            class="my-0 py-0"
            color="#3699FF"
            hide-details
          ></v-checkbox>
        </template>

        <template #cell(actions)="row">
          <div class="d-flex justify-content-end">
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-2"
              @click.prevent="select_shop_order_clicked(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>

            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="member_selected(row.item.member.member_id)" v-if="row.item.member">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-user color-primary"></i>
              </span>
            </a>
            
            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="copyDinteroId(row.item)" v-if="row.item.dintero_id">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-link color-primary"></i>
              </span>
            </a>

            <a
              class="btn btn-icon btn-light btn-sm mx-2"
              @click.prevent="download_receipt(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/file-invoice-dollar-solid.svg"></inline-svg>
              </span>
            </a>

            <a
              v-if="row.item.amount > 0 && !row.item.is_paid"
              class="btn btn-icon btn-light btn-sm mx-2"
              @click.prevent="goto_payment_link(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/credit-card.svg"></inline-svg>
              </span>
            </a>


          </div>
        </template>
      </b-table>

    </div>

  </div>

</template>


<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

import ShopOrderSendLinkModal from './ShopOrderSendLinkModal.vue';
import ShopOrderFormModal from '@/view/pages/ml/shop_order/ShopOrderFormModal.vue';


export default {
  name: 'ShopOrderTable',
  mixins: [ toasts ],
  props: [],
  emits: ['shop_order_selected', 'show_history_toggled'],
  components: {
    ShopOrderFormModal,
    ShopOrderSendLinkModal
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('show_history_toggled', newcheck);
    },
    currentPeriodId(newValue) {
      if (newValue) {
        this.get_page_count();
      }
    }
  },
  mounted() {

    this.initGet();
  },
  methods: {

    shop_order_updated(shop_order) {
      this.$refs['orderTable'].refresh();
    },

    shop_order_deleted(shop_order_id) {
      this.$refs['orderTable'].refresh();

      this.toastr('success', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.DELETED'));
    },

    shop_order_selected(shop_order) {
      this.selected_item = { ...shop_order }

      this.$nextTick(()=>{
        this.$refs['shop-order-modal'].show();
      });
    },

    member_updated() {
      this.$refs['orders-table'].refresh();
    },

    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-modal'].show(member_id);
    },

    is_stripe(item) {
      if (item.stripe_client_secret !== null && item.stripe_client_secret !== undefined && item.stripe_client_secret !== '' && item.stripe_client_secret.length > 8) {
        return true;
      }

      return false;
    },

    goto_payment_link(item) {

      const url =
        this.is_stripe(item)
          ? `${get_domain_url()}/stripe-payment/${item.member_id}?client_secret=${item.stripe_client_secret}`
          : `${get_domain_url()}/resume-payment-session/${item.shop_order_id}/${item.token}`;

      navigator.clipboard
        .writeText(
          url
        )
        .then(() => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SHOP_ORDER.CLIPBOARD_COPY'));
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_CLIPBOARD_COPY'));
        });


      //window.open(url, '_blank');
    },

    get_all_selected_shop_item_ids() {
      const ids = this.copy_rows.filter(item => item.selected).map(item => (item.shop_order_id));

      return ids;
    },


    selectRow(index) {
      if (this.$refs.orderTable.isRowSelected(index)) {
        this.$refs.orderTable.unselectRow(index);
      } else {
        this.$refs.orderTable.selectRow(index);
      }
    },

    on_sort_changed(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },

    select_shop_order_clicked(shop_order) {
      this.shop_order_selected(shop_order);
    },

    download_receipt(shop_order) {
      axios
        .get(`/shop_order/receipt/${shop_order.shop_order_id}`)
        .then(res => {
          if (res.status === 200) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
          else {
            console.error('invalid status ' + res.status);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
          }
        })
        .catch(err => {
          console.error('download_receipt', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
        });
    },
    send_links_modal_open() {
      this.selected_shop_order_ids = this.get_all_selected_shop_item_ids();
      this.$refs['shop_order_send_link_modal'].show();
    },
    export_xlsx_clicked() {

      const options = this.get_options();

      axios
        .post(`/shop_order/xlsx`, {
          options,
          period_id: this.currentPeriodId
        })
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
        });
    },
    on_filtered(userFilteredOrders) {

    },

    get_options() {

      const options = {

      };


      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      if (this.filters.text !== '') {
        options.text = this.filters.text;
      }

      return options;
    },

    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['orderTable'].refresh();
    ajax_pagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();


      axios
        .post(`/shop_order/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          let i = 0;
          for (const a of arr) {
            a.index = i++;
            a.selected = this.copy_rows.findIndex(id => id === a.shop_order_id) >= 0;
          }

          this.copy_rows = arr;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },


    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/shop_order/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options })
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_GET_NUM_ORDERS'));
        });
    },

    initGet() {
      this.get_page_count();
    },

    
    refresh() {
      this.$refs['orderTable'].refresh();
    },

    copyDinteroId(item) {
      navigator.clipboard
        .writeText(
          item.dintero_id
        )
        .then(() => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SHOP_ORDER.CLIPBOARD_COPY'));
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_CLIPBOARD_COPY'));
        });
    },

    rowClass(item, type) {
      return {
        striped: 'true'
      }
    },

  },
  data() {

    return {
      selected_item: null,
      member_id: null,
      copy_rows: [],
      selected_shop_order_ids: [],
      statuses: [],
      statuses_options: [
        { value: 'CREATED', text: this.$t('COMMON.CREATED') },
        { value: 'WAIT_PAYMENT', text: this.$t('COMMON.WAIT_PAYMENT') },
        { value: 'PARTIALLY_PAID', text: this.$t('COMMON.PARTIALLY_PAID') },
        { value: 'PAID_IN_FULL', text: this.$t('COMMON.PAID_IN_FULL') },
        { value: 'DELETED', text: this.$t('COMMON.DELETED') },
        { value: 'DONE', text: this.$t('COMMON.DONE') }
      ],
      orderBy: { created_at: 'desc' },
      per_page: 100,
      current_page: 1,
      totalRows: 0,
      filters: {
        textFilter: '',
        created_from: null,
        created_to: null,
        statuses: []
      },
      headers: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          tdClass: 'td-short',
          thClass: 'td-short'
        },
        {
          key: 'member.member_id',
          label: this.$t('COMMON.MEMBER_PERSON'),
          sortable: false,
          formatter: (_, __, item) => {
            if (item.member) {
              if (item.member.reg) {
                return this.$t('COMMON.WAITING');
              }
              return '(M) ' + item.member.firstname + ' ' + item.member.lastname;
            }
            else if (item.person) {
              return '(P) ' + item.person.firstname + ' ' + item.person.lastname;
            }
            return '-';
          },
          tdClass: 'td-overflow'
        },
        {
          key: 'shop_order_id',
          label: 'Ref',
          sortable: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'created_at',
          label:  this.$t('COMMON.CREATED'),
          sortable: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'shop_order_items',
          label: this.$t('PAGES.SHOPITEM.ARTICLE'),
          sortable: false,
          formatter: (_, __, item) => {
            return item.shop_order_items.length > 0 ? item.shop_order_items[0].shop_item.name : '-';
          },
          tdClass: 'td-overflow'
        },
        {
          key: 'is_paid',
          label:  this.$t('COMMON.PAID_SHORT'),
          formatter: (_, __, item) => {
            return item.is_paid ?  this.$t('COMMON.YES') : this.$t('COMMON.NO');
          },
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'amount',
          label: this.$t('COMMON.TOTAL'),
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'actions',
          label: ''
        }
      ]
    };
  }
};
</script>


<style lang="scss" scoped>

:deep .striped {
  border-bottom: 1px solid #d9d9d9 !important;
}

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.svg-icon > svg {
  fill: #3699FF;
}

:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

:deep td {
  vertical-align: middle !important;
}

</style>